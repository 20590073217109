.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #f4f7f6;
  font-family: 'Arial', sans-serif;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.error-message {
  color: #d9534f;
  margin-bottom: 20px;
  border: 1px solid #ebccd1;
  padding: 10px;
  border-radius: 8px;
  background-color: #f2dede;
  text-align: center;
  width: 100%; /* Match input width */
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 320px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 8px;
  background-color: #ffffff;
}

.form-label {
  margin-bottom: 5px;
  color: #5a5a5a;
  font-weight: 600;
}

.form-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
}

.submit-button, #signInDiv > div {
  padding: 12px;
  background-color: white;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s;
  width: 100%; /* Ensure full width */
  display: flex;
  justify-content: center;
}

.submit-button:hover {
  background-color: #357ABD;
}

.submit-button.loading {
  background-color: #5bc0de;
  cursor: not-allowed;
}

.submit-button.loading:hover {
  background-color: #5bc0de;
}

#signInDiv {
  margin-top: 15px;
  display: flex; /* Align button correctly */
}

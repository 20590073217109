.menuContainer {
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.menuHeader {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.categorySelector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.categoryLabel {
    margin-right: 10px;
    font-size: 16px; /* Increased label size for better visibility */
}

.categorySelect {
    padding: 8px 12px; /* Increased padding for better tap area on mobile */
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px; /* Make dropdown text larger */
    cursor: pointer; /* Make it clear it's selectable */
}

.contentContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    height: 500px; /* Adjust this height as needed */
}

.menuItemsSection {
    padding: 10px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.menuList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.menuName {
    flex: 1;
    font-weight: bold;
}

.menuPrice {
    margin-right: 10px;
}

.smallButton {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 60px; /* Fixed size */
}

.orderSection {
    padding: 10px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.orderTitle {
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
}

.orderList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.orderItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.removeButton {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #ff4b4b;
    color: white;
    cursor: pointer;
    width: 60px; /* Fixed size */
}

.emptyOrderMessage {
    text-align: center;
    color: #777;
}

.orderFooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.totalAmountLabel {
    font-weight: bold;
    margin-right: 15px;
}

.totalAmount {
    font-size: 18px;
    font-weight: bold;
    margin-right: auto;
}

.placeOrderButton {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 120px; /* Fixed width for Place Order button */
}

.placeOrderButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.successMessage {
    margin-top: 20px;
    text-align: center;
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .contentContainer {
        grid-template-columns: 1fr;
    }

    .menuHeader {
        font-size: 20px;
    }

    .orderTitle {
        font-size: 18px;
    }

    .menuItem,
    .orderItem {
        font-size: 14px;
    }

    .categorySelector {
        flex-direction: row; /* Keeps elements on the same line */
        flex-wrap: wrap;
        justify-content: center;
    }

    .categoryLabel {
        margin-right: 10px;
    }

    .categorySelect {
        width: auto; /* Adjust width to fit content */
    }
}

@media (max-width: 480px) {
    .menuHeader,
    .orderTitle {
        font-size: 16px;
    }

    .menuItem,
    .orderItem {
        font-size: 12px;
    }

    .placeOrderButton {
        padding: 6px 12px;
    }
}


.disabledItem {
    opacity: 0.5;  /* Grey out the item */
    pointer-events: none;  /* Disable interactions */
}

.smallButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

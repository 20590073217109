.upi-payment-container {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upi-payment-header {
    color: #333;
    margin-bottom: 20px;
}

.form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-reader-container {
    width: 100%;
    height: 300px; /* Fixed height for the QR reader container */
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 10px;
    position: relative; /* Positioning for absolute positioning of the QR reader */
}

.qr-reader {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute; /* Absolute positioning to cover the container */
    top: 0;
    left: 0;
}

.confirm-group {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.confirm-group input[type="checkbox"] {
    margin-right: 10px;
}

.button-group {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.button {
    flex-grow: 1;
    margin: 0 5px;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: #45a049;
}

.button:disabled {
    background-color: #ccc;
    cursor: default;
}

.center {
    text-align: center;
    margin-top: 5px;
}

.error-message {
    color: red;
    margin-top: 5px;
    font-weight: bold;
    text-align: center;
}

.saved-upi {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    color: #4CAF50;
}

nav {
  background-color: #303; /* Dark background for the nav bar */
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between; /* Spaces items evenly */
  align-items: center; /* Vertically centers items */
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

.nav-link, button.nav-link {
  color: white;
  text-decoration: none;
  margin-right: 20px;
  padding: 8px 15px; /* Slightly reduced padding for a more compact look */
  border-radius: 5px; /* Rounded edges */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
  display: inline-flex; /* Aligns text and icons */
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers content horizontally */
}

.nav-link:hover, button.nav-link:hover {
  background-color: #555; /* Darkens on hover */
  color: #ddd; /* Lightens text color for contrast */
}

button.nav-link {
  background-color: #f44336; /* Red color for logout to make it stand out */
  border: none;
  cursor: pointer;
}

/* Special styling for active nav link */
.nav-link.active {
  background-color: #666; /* Slightly different background for active link */
  color: #fff;
}

/* Responsive adjustments for better mobile view */
@media (max-width: 768px) {
  nav {
    justify-content: center; /* Centers navigation links on smaller screens */
    padding: 20px; /* Adds more padding for touch targets */
  }

  .nav-link, button.nav-link {
    margin: 5px 10px; /* Reduces margin for compact layout */
    padding: 8px 12px; /* Adjust padding to fit smaller screens */
  }
}
/*this*/
.navbar {
  background-color: #103; /* Dark background for contrast */
  color: white;
  padding: 10px 20px;
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space out nav items */
  align-items: center; /* Align items vertically */
  flex-wrap: wrap; /* Allow items to wrap as needed */
  border-bottom: 2px solid #eee; /* Add a border at the bottom for visual separation */

    z-index: 1050; /* Higher than other page elements */
    position: relative; /* Position relative to allow z-index to function */

}

.nav-link, button.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 10px; /* Adjust margins for spacing */
  padding: 10px; /* Add padding for clickable area */
  border-radius: 5px; /* Optional: Rounded corners for buttons/links */
}

.nav-link:hover, button.nav-link:hover {
  background-color: #555; /* Hover effect for interactivity */
}

/* Navigation.css */
.logout-btn {
  display: inline-block !important; /* Ensures the button does not stretch to full width */
  width: auto !important; /* Allows the button to size according to its content */
  padding: 8px 15px; /* Gives some padding around the text for a better visual appearance */
  background-color: #ff5722 !important; /* Example: A deep orange color, you can choose any */
  color: white !important; /* Text color */
  border: none !important; /* Removes any border */
  border-radius: 4px !important; /* Rounded corners */
  cursor: pointer !important; /* Changes cursor to pointer on hover */
  font-size: 1rem !important; /* Adjust font size as needed */
  margin-left: 15px !important; /* Adds some space to the left of the button, adjust as needed */
  text-decoration: none !important; /* Removes underline from links */
}

.logout-btn:hover {
  background-color: #e64a19 !important; /* A slightly darker shade on hover for feedback */
}
/*new*/
/* Navigation.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #855;
  color: white;
}

.nav-menu {
  display: flex;
  gap: 10px;
}

.nav-link {
  color: white;
  text-decoration: none;
}

/* Hamburger Menu */
.hamburger-menu {
  display: none;
  cursor: pointer;
}

/* Responsive */
@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background-color: #333;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
  }

  .nav-menu.active {
    display: flex;
  }

  .hamburger-menu {
    display: block;
  }
}
.cart-icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.notification-dropdown-container, .cart-icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

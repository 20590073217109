/* src/components/CustomerOrdersPage.module.css */
.ordersContainer {
    margin: 20px auto;
    padding: 10px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    overflow-x: auto;
}

.ordersHeader {
    color: #333;
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
}

.tableWrapper {
    overflow-x: auto;
    width: 100%;
}

.ordersTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.ordersTable th, .ordersTable td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.ordersTable th {
    background-color: #e8e8e8;
    font-size: 14px;
    font-weight: bold;
}

.ordersTable tr:hover {
    background-color: #f1f1f1;
}

.productItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 8px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.productName {
    flex: 1;
    font-size: 14px;
}

.quantityBadge {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    padding: 3px 8px;
    font-size: 12px;
}

.loading, .error, .noOrders {
    text-align: center;
    padding: 20px;
    font-size: 18px;
}

.loading {
    color: #333;
}

.error {
    color: #d33;
}

.noOrders {
    color: #666;
}

@media (max-width: 768px) {
    .ordersHeader {
        font-size: 22px;
    }

    .ordersTable th, .ordersTable td {
        font-size: 12px;
        padding: 8px;
    }

    .productItem {
        font-size: 12px;
    }

    .quantityBadge {
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .ordersHeader {
        font-size: 20px;
    }

    .ordersTable th, .ordersTable td {
        font-size: 10px;
        padding: 6px;
    }

    .productItem {
        font-size: 10px;
    }

    .quantityBadge {
        font-size: 8px;
    }
}

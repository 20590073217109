.menuContainer {
    display: flex;
    justify-content: space-between; /* Aligns children (menu and order) on the main-axis */
    padding: 20px;
    max-width: 1200px; /* Increased width to accommodate both sections */
    margin: auto;
    background: #fff;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    border-radius: 10px;
}


.menuHeader,
.orderTitle {
    border-bottom: 2px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 10px;
    color: #333;
    font-size: 24px;
}

.menuList,
.orderList {
    list-style: none;
    padding: 0;
}

.menuItem,
.orderItem {
    background-color: #f9f9f9; /* Light gray background for each item */
    padding: 16px 20px; /* Increased padding for larger cards */
    margin-bottom: 10px; /* Space between cards */
    border-radius: 8px; /* Rounded corners for the cards */
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05); /* Subtle shadow for depth */
}




.button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 16px; /* Standardized padding */
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: 100px; /* Set a fixed width for all buttons */
    text-align: center; /* Centers the text within the button */
    box-sizing: border-box; /* Includes padding and border in the width calculation */
}

.button:hover {
    background-color: #0056b3;
}

.removeButton {
    background-color: #dc3545; /* Maintains distinct color for removal actions */
}

.removeButton:hover {
    background-color: #c82333;
}

.orderButton {
    background-color: #28a745; /* Distinct color for placing an order */
    margin-top: 20px; /* Adds space above the order button */
    width: 150px; /* Slightly wider for prominence */
}

.orderButton:hover {
    background-color: #218838;
}







.orderSection {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
}

.menuSection, .orderSection {
    flex: 1; /* Each section takes equal width */
    margin: 0 10px; /* Spacing between sections */
}



.menuItem {
    display: flex;
    justify-content: space-between; /* This already ensures that children elements are spaced out */
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.menuName {
    flex: none; /* Removes the flex property to specify a fixed width */
    width: 25ch; /* Sets the width based on character count */
    text-align: left;
    overflow: hidden; /* Prevents text from overflowing */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    white-space: nowrap; /* Keeps the text on a single line */
    margin-right: 20px; /* Ensures there's space between the name and price */
}

.menuPrice {
    flex: none; /* Optionally specify sizing if needed */
    margin-right: 20px; /* Ensures there's space between the price and button */
    width: 9ch; /* Sets the width based on character count */

}



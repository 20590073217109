.menuManagementContainer {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.menuHeader {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.menuTable {
    width: 100%;
    border-collapse: collapse;
}

.menuTable th,
.menuTable td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.menuTable th {
    background-color: #f5f5f5;
    font-weight: bold;
}

.categorySelect {
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.availabilityCheckbox {
    cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .menuManagementContainer {
        padding: 10px;
    }

    .menuHeader {
        font-size: 18px;
    }

    .menuTable th,
    .menuTable td {
        padding: 8px;
    }

    .categorySelect {
        padding: 4px;
    }
}

@media (max-width: 480px) {
    .menuManagementContainer {
        padding: 5px;
    }

    .menuTable th,
    .menuTable td {
        padding: 5px;
        font-size: 12px;
    }

    .menuHeader {
        font-size: 16px;
    }

    .categorySelect {
        padding: 3px;
        font-size: 12px;
    }

    .menuTable {
        display: block;
        overflow-x: auto;
    }
}

/* WaiterService.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.waiter-service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
}

.service-button {
    padding: 12px 24px;
    margin-bottom: 15px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
    box-shadow: 0 2px 5px rgba(0,123,255,0.3);
}

.service-button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0,123,255,0.5);
    transform: translateY(-2px);
}

.service-button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    box-shadow: none;
}

.connection-status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #666;
    margin-top: 20px;
}

.icon-spinner, .icon-connected, .icon-error {
    margin-right: 10px;
}

.icon-connected {
    color: green;
}

.icon-error {
    color: red;
}

/* src/components/OrdersPage.module.css */
.ordersContainer {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-x: auto;
}

.ordersHeader {
    color: #333;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.tableWrapper {
    width: 100%;
    overflow-x: auto;
}

.ordersTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    min-width: 800px;
}

.ordersTable th, .ordersTable td {
    text-align: left;
    padding: 8px 12px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}

.ordersTable th {
    background-color: #f5f5f5;
    color: #333;
    font-weight: bold;
}

.ordersTable td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.paymentStatusBadge {
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    width: fit-content;
    color: white;
}

.paymentStatusPaid {
    background-color: #28a745;
}

.paymentStatusPending {
    background-color: #ffc107;
    color: black;
}

.paymentStatusFailed {
    background-color: #dc3545;
}

.itemsList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.productItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.quantityBadge {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    padding: 2px 6px;
    font-size: 12px;
}

.initiatePaymentButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.initiatePaymentButton:hover {
    background-color: #0056b3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.loading, .error, .noOrders {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    color: #666;
}

@media (max-width: 768px) {
    .ordersHeader {
        font-size: 20px;
    }

    .ordersTable th, .ordersTable td {
        font-size: 12px;
        padding: 6px 10px;
    }

    .productItem {
        font-size: 12px;
    }

    .quantityBadge {
        font-size: 10px;
    }

    .initiatePaymentButton {
        padding: 6px 10px;
    }
}

@media (max-width: 480px) {
    .ordersHeader {
        font-size: 18px;
    }

    .ordersTable th, .ordersTable td {
        font-size: 10px;
        padding: 4px 8px;
    }

    .productItem {
        font-size: 10px;
    }

    .quantityBadge {
        font-size: 8px;
    }

    .initiatePaymentButton {
        padding: 5px 8px;
    }
}

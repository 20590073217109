/* Main container styling */
.business-payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: auto;
    max-width: 600px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
}

/* Header styling */
.business-payment-header {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

/* Labels and inputs */
.form-label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-size: 14px;
}

.input-field,
.file-input-label {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.2s ease-in-out;
}

.input-field:hover,
.file-input-label:hover {
    border-color: #80bdff;
}

.input-field:focus,
.file-input-label:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.25);
    outline: none;
}

.file-input {
    display: none;
}

/* Upload status and button styling */
.upload-status {
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #666;
}

.save-button,
.edit-button {
    width: 100%;
    padding: 12px 20px;
    margin-top: 10px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button:hover,
.edit-button:hover {
    background-color: #45a049;
}

.save-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Account details display */
.account-details-display {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Error and success messages */
.error-message,
.success-message {
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    background-color: #f2dede;
    border: 1px solid #ebccd1;
}

.error-message {
    color: #d9534f;
}

.success-message {
    color: #5cb85c;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .business-payment-container {
        width: 90%;
        padding: 15px;
    }

    .save-button,
    .edit-button {
        font-size: 14px;
    }

    .form-label,
    .input-field,
    .file-input-label {
        font-size: 12px;
    }
}

/* Tooltip styles */
.tooltip-icon {
    display: inline-block;
    margin-left: 5px;
    color: #4CAF50;
    cursor: help;
}

.tooltip-content {
    margin-top: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    width: auto;
    font-size: 14px;
}

/* Modal styles */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1051;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: showModal 0.3s ease-out;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@keyframes showModal {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Info icon styling */
.info-icon {
    cursor: pointer;
    margin-left: 5px;
    font-size: 1.2em;
    color: #007bff;
}

.info-icon:hover {
    color: #0056b3;
}




/* Reset margin and padding around the checkbox and label for consistent styling */
.form-consent {
    display: flex;
    align-items: center; /* Ensures vertical alignment */
    margin: 20px 0;
}

.checkbox-label {
    margin-left: 8px; /* Adjusts horizontal spacing between checkbox and label */
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 16px; /* Ensures text is large enough to align well */
    line-height: 20px; /* Adjusts the line height to match checkbox height */
}

/* Custom styling for the checkbox to improve alignment */
input[type="checkbox"] {
    margin: 0;
    vertical-align: middle; /* Aligns checkbox vertically with the middle of the text */
    position: relative;
    top: -1px; /* Adjusts vertical position to align with the text */
    width: 20px; /* Width of the checkbox */
    height: 20px; /* Height of the checkbox */
    cursor: pointer;
}

/* Enhanced focus and hover states for better user experience */
input[type="checkbox"]:focus + .checkbox-label,
input[type="checkbox"]:hover + .checkbox-label {
    background-color: #f4f4f4; /* Light grey background on hover/focus */
}

input[type="checkbox"]:checked + .checkbox-label {
    font-weight: bold; /* Makes label bold when checked */
}



.productList {
    list-style: none;
    padding: 0;
    margin-top: 20px;
}

.productItem {
    background-color: #f9f9f9;
    border-left: 5px solid #007bff;
    padding: 12px 20px;
    margin: 10px 0;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.productItem:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.uploadButton {
    background-color: #007bff;
    background: linear-gradient(to right, #0062cc, #007bff);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.uploadButton:hover {
    background-color: #0056b3;
    background: linear-gradient(to right, #004085, #0056b3);
}

.fileInput {
    padding: 10px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease;
}

.fileInput:hover {
    border-color: #007bff;
}

.formSection {
    background-color: #f8f9fa;
    background: linear-gradient(to bottom, #f8f9fa, #e9ecef);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.productItem {
    background-color: #f9f9f9;
    border-left: 5px solid #007bff;
    padding: 12px 20px;
    margin: 10px 0;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.productName {
    flex: 1;
    font-size: 18px;
    color: #333;
}

.productPrice {
    font-weight: bold;
    color: #007bff;
    padding-left: 20px;
    font-size: 18px;
}






/* Button styling for upload, save, cancel, and edit actions */
.uploadButton, .saveButton, .cancelButton, .editButton {
    background-color: #007bff; /* Default blue color for buttons */
    color: white;
    padding: 8px 12px; /* Uniform padding for all buttons */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* Appropriate font size for readability */
    margin-left: 10px; /* Space between buttons */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    width: auto; /* Auto width to fit content */
    min-width: 80px; /* Minimum width to keep buttons visually consistent */
}

/* Hover effects for all buttons */
.uploadButton:hover, .saveButton:hover, .cancelButton:hover, .editButton:hover {
    background-color: #0056b3; /* Darker shade of blue on hover */
}

/* Specific styling for cancel button */
.cancelButton {
    background-color: #f44336; /* Red color to signify a potentially destructive action */
}

.cancelButton:hover {
    background-color: #d32f2f; /* Darker red on hover */
}

/* Specific styling for save button */
.saveButton {
    background-color: #4CAF50; /* Green color to signify a positive action like 'save' */
}

.saveButton:hover {
    background-color: #388E3C; /* Darker green on hover */
}

/* Specific styling for edit button */
.editButton {
    background-color: #FFC107; /* Amber color for edit actions */
}

.editButton:hover {
    background-color: #FFB300; /* Darker amber on hover */
}

/* Additional styles if needed for delete button */
.deleteButton {
    background-color: #f44336; /* Red color for delete actions */
    color: white;
}

.deleteButton:hover {
    background-color: #d32f2f; /* Darker red on hover */
}

/* Input field styling for edit actions */
.editInput {
    padding: 8px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px; /* Ensures text size is suitable for user interaction */
}
















.MenuImageUpload .button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    transition: background-color 0.3s;
    width: auto;
    min-width: 80px; /* Ensures uniform minimum width */
}

.MenuImageUpload .editButton {
    background-color: #FFC107;
    width: 80px; /* Fixed width for uniformity */
}

.MenuImageUpload .editButton:hover {
    background-color: #FFB300;
}

.MenuImageUpload .cancelButton {
    background-color: #f44336;
}

.MenuImageUpload .cancelButton:hover {
    background-color: #d32f2f;
}

.MenuImageUpload .saveButton {
    background-color: #4CAF50;
}

.MenuImageUpload .saveButton:hover {
    background-color: #388E3C;
}

.MenuImageUpload .uploadButton:hover {
    background-color: #0056b3;
}

.MenuImageUpload .fileInput:hover {
    border-color: #007bff;
}

.MenuImageUpload .productItem:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

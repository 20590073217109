.homeContainer {
  display: flex;
  align-items: center; /* Vertically center in the viewport */
  justify-content: center; /* Horizontally center in the viewport */
  height: 100vh;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background 0.3s ease-out;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 100%), url('../assets/image.webp');
  border-radius: 15px; /* Adds rounded corners */
  overflow: hidden; /* Ensures no child elements bleed outside the rounded edges */
}
.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 250px;
    background-color: #fff; /* Assuming 'background.paper' is white */
    padding: 16px;
    border-radius: 2px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width: 600px) {
    .modalContainer {
        width: 80%; /* Adjust width as necessary */
    }
}

/* Add more media queries as needed for different breakpoints */



.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures that all children align in the center horizontally */
  justify-content: center; /* Aligns children vertically in the center */
  background-color: rgba(255, 255, 255, 0.75);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(3px);
  width: 100%; /* Adjusts to the width of `homeContainer` */
  max-width: 600px; /* Maximum width to maintain design consistency */
  overflow: hidden; /* Prevents children from overflowing */
  min-height: 200px; /* Ensures there is enough height for centering */
}
.typography-container {
  margin-bottom: 200px; /* Adds space between the text and the button */
}

.button {
  margin-top: 20px; /* Adds space above the button */
  background-color: var(--primary-color);
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.button:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .homeContainer {
    padding: 10px; /* Smaller padding on smaller screens */
  }

  .container {
    padding: 15px; /* Adjusts padding inside the container on smaller screens */
  }

  .button {
    font-size: 14px; /* Smaller font size on smaller screens */
    padding: 8px 15px; /* Adjusts padding inside the button on smaller screens */
  }
}

.business-registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
    width: 100%;
    max-width: 500px;
}

.business-registration-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 25px;
}

.business-registration-form label {
    display: block;
    margin-bottom: 15px;
    color: #666;
    font-weight: bold;
}

.business-registration-form input,
.business-registration-form select {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.business-registration-form button {
    width: 100%;
    padding: 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.business-registration-form button:hover {
    background-color: #0056b3;
}

.business-registration-message {
    color: #28a745;
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
}

/* Add responsiveness to form */
@media (max-width: 600px) {
    .business-registration-container {
        padding: 15px;
        margin: 20px;
        width: calc(100% - 40px);
    }
}

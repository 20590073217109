.registration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f4f7f6;
}

.content-container {
  width: 100%;
  max-width: 500px; /* Or whatever your desired max width is */
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.message-container {
  width: 100%;
  max-width: 500px; /* Matching the content-container width */
  text-align: center;
  margin-bottom: 20px; /* Adjust based on your layout needs */
}

/* Ensure your .error-message, .modal-backdrop, and .modal-content styles are correctly applied as before */


.error-message {
  color: #d9534f;
  font-size: 0.85rem;
  margin-bottom: 15px;
  text-align: center;

}
.form-container {
  padding: 20px;
    max-width: 500px; /* Or whatever your desired max width is */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  /* Other styling as before */
}

/*lkajsdflkjlasd*/

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f7f6;
  margin: 0;
  font-family: 'Arial', sans-serif;
  color: #4a4a4a;
  padding: 20px;
}

.title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #333;
}

input, textarea, button {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1em;
  transition: border 0.3s;
}

input:focus, textarea:focus, button:focus {
  border-color: #007bff;
  outline: none;
}

button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: #d9534f;
  font-size: 0.85em;
  margin-top: 5px;
}

.success-container {
  background-color: #dff0d8;
  border: 1px solid #d0e9c6;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  margin: 20px auto;
}

a {
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.5em;
  }

  .form-container {
    padding: 15px;
  }

  input, textarea, button {
    font-size: 0.9em;
  }
}




.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 11;
}

/* CustomerPaymentSetup.css */
.customer-payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 500px;
    margin: auto;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
    border: 1px solid #e0e0e0;
}

.customer-payment-container h2 {
    color: #333;
    margin-bottom: 20px; /* Add more space below the header */
}

.form-label {
    margin-bottom: 8px;
    font-size: 16px;
    color: #444;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.input-field:focus {
    border-color: #4CAF50; /* Focus state to indicate active input */
    outline: none;
}

.button {
    padding: 12px 24px;
    margin-top: 20px;
    font-size: 16px;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
    background-color: #45a049;
    transform: translateY(-2px); /* Subtle lift effect on hover */
}

.button:active {
    transform: translateY(1px); /* Subtle press effect on active */
}

.button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
}

.message {
    color: #d9534f;
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
}

/* Responsive adjustments for mobile views */
@media (max-width: 768px) {
    .customer-payment-container {
        width: 90%;
        padding: 15px;
    }

    .form-label, .input-field, .button {
        font-size: 14px;
    }
}

/* Modal.module.css */



@keyframes modalFadeIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}
.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Darker background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it's above other content */
}

.modalContent {
    position: relative; /* Needed for absolute positioning of children */
    background: white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    max-width: 450px;
    width: 90%;
    box-sizing: border-box;
}

.closeButton {
    position: absolute;
    top: -15px; /* Positioning above the modal */
    right: -15px; /* Positioning to the right of the modal */
    width: 30px;
    height: 30px;
    border: none;
    background-color: #ff5f57; /* Red for attention, can adjust based on design language */
    color: white;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.closeButton:hover {
    background-color: #e0483e; /* Slightly darker on hover for feedback */
}


.paymentLink {
    color: #1a73e8; /* Brighter blue for better visibility */
    text-decoration: none;
    font-size: 18px; /* Slightly larger for readability */
    font-weight: bold; /* Make it bold */
    background-color: #f1f3f4; /* Subtle background */
    padding: 12px 15px;
    border-radius: 8px;
    display: block; /* Block to take full width of content */
    text-align: center; /* Center the text */
    transition: background-color 0.2s ease-in-out;
    margin-top: 30px; /* More space from the text */
}

.paymentLink:hover {
    background-color: #e8f0fe; /* Light blue on hover for interaction */
    text-decoration: underline; /* Add underline on hover */
}

.qr-scanner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.qr-reader {
  width: 100%;
  max-width: 500px;
  height: 250px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners for a smoother look */
}

.scanner-inactive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8); /* Semi-transparent white background */
  border-radius: 8px; /* Consistent rounded corners */
}

.scan-icon {
  width: 100%;
  max-width: 500px;
  object-fit: contain;
}

.scan-button {
  width: 100%;
  max-width: 500px;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  color: white;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25); /* Shadow for the button */
}

.scan-button:hover {
  background-color: #45a049;
}

.scan-info {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}






.qr-reader {
  width: 100%;
  max-width: 500px;
  height: 250px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.scanner-inactive {
  display: none; /* Hide the scanner when not active, or adjust as needed */
}
